import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Icon from "./Icon";
import Arrow from "../../../../images/arrow_right_black.inline.svg";
import Quotes from "../../../../images/quotes.inline.svg";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import SwiperCore, { Pagination, Navigation, EffectFade, Controller, Autoplay } from "swiper/core";
import { FaStar } from "react-icons/fa";

SwiperCore.use([Pagination, Navigation, EffectFade, Controller, Autoplay]);

export default function Testimonials({
  testimonialsData,
  customHeading1,
  customHeading2,
  hideReadMore,
  showStars,
  darkLightMode,
  index,
  overrideHeadingSize,
}) {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const [thirdSwiper, setThirdSwiper] = useState(null);
  const prevRef = useRef();
  const nextRef = useRef();
  const targetRef = useRef();
  const newPositionRef = useRef();
  const originalPositionRef = useRef();

  const repositionElement = () => {
    if (typeof window !== undefined) {
      const target = targetRef.current;
      const newPosition = newPositionRef.current;
      const originalPosition = originalPositionRef.current;

      if (window.innerWidth < 768) {
        newPosition.parentNode.insertBefore(target, newPosition);
      } else {
        originalPosition.parentNode.insertBefore(target, originalPosition);
      }
    }
  };

  useEffect(() => {
    repositionElement();

    if (typeof window !== undefined) {
      window.addEventListener("resize", repositionElement);
    }
    return () => {
      window.removeEventListener("resize", repositionElement);
    };
  }, []);

  return (
    <Styling id={`section_${index + 1}`} darkLightMode={darkLightMode}>
      <section className="testimonials">
        <div className="left">
          <h3 className={`title ${overrideHeadingSize === true ? "sizing_override" : ""}`}>
            <div>{customHeading1 ? customHeading1 : "Student"}</div>
            <div>
              <Arrow className="testimonials_arrow" /> {customHeading2 ? customHeading2 : "Success"}
            </div>
          </h3>
          <div className="mobileSlider">
            <div ref={newPositionRef}></div>
          </div>
          <div className="lower">
            <Swiper
              // spaceBetween={typeof window !== 'undefined' ? window.innerWidth / 100 * 1 : ''}
              slidesPerView={1}
              className="swiper-1"
              loop={true}
              modules={[EffectFade]}
              effect="fade"
              onSwiper={setFirstSwiper}
              controller={{ control: secondSwiper }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}>
              {testimonialsData.map((testi, index) => {
                const test = testi.testimonial;
                return (
                  <SwiperSlide>
                    {test?.testimonialFields?.courseCompleted?.title ? (
                      <Icon course={test.testimonialFields.courseCompleted.title} />
                    ) : (
                      ""
                    )}
                    <div className="lower-quote-wrapper">
                      <Quotes className="quotes" />
                      <div className="quote">
                        {test?.testimonialFields?.archiveQuote || testi?.testimonialText}
                      </div>
                      {test?.title ? <div className="person">-{test?.title}</div> : ""}
                      {showStars ? (
                        <div className="stars">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="buttons">
                        <button
                          ref={prevRef}
                          onClick={(swiper) => {
                            secondSwiper.slideToLoop(secondSwiper.realIndex - 1);
                          }}
                          className="arrow prev"
                          aria-label="Previous">
                          <BsArrowLeft />
                        </button>
                        <button
                          ref={nextRef}
                          onClick={(swiper) => {
                            secondSwiper.slideToLoop(secondSwiper.realIndex + 1);
                          }}
                          className="arrow next"
                          aria-label="Next">
                          <BsArrowRight />
                        </button>
                        {hideReadMore !== true ? (
                          <Link
                            to={`/student-success/${test?.slug}`}
                            className="capsule_button white">
                            Read more
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="originalPosition" ref={originalPositionRef}></div>

        <Swiper
          slidesPerView={1}
          loop={true}
          className="swiper-2"
          effect="fade"
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          ref={targetRef}>
          {testimonialsData.map((testi, index) => {
            const test = testi?.testimonial;
            const theImage = getImage(
              testi?.testimonialImage?.localFile ||
                testi?.testimonial?.testimonialFields?.profileImage?.localFile
            );
            const theImage2 = getImage(testi?.testimonialImage2?.localFile);
            // //console.log(testi.testimonial )
            const link = test?.testimonialExternalLink;

            return (
              <SwiperSlide>
                <GatsbyImage image={theImage} alt={"test"} className="image" />
                <GatsbyImage image={theImage2} alt={"test"} className="second_image" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </Styling>
  );
}
