import React from "react";
import { graphql } from "gatsby";
import HomeIntro from "../components/page-components/Homepage/HomeIntro";
import Testimonials from "../components/page-components/Homepage/Testimonials";
import FeaturedProgrammes from "../components/page-components/Homepage/FeaturedProgrammes";
import ImageText from "../components/reusable/ImageText";
import ProductionTools from "../components/page-components/Homepage/ProductionTools";
import Features from "../components/page-components/Homepage/Features";
import Seo from "../components/global/seo";

const Home = ({ data }) => {
  return (
    <section>
      <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
      <HomeIntro
        isHome={true}
        background={data.wpPage.homepage.heroBlock.background}
        backgroundImage={data.wpPage.homepage.heroBlock.backgroundImage}
        heading={data.wpPage.homepage.heroBlock.heading}
        backgroundVideo={data.wpPage.homepage.heroBlock.backgroundVideo}
      />
      <Testimonials testimonialsData={data.wpPage.homepage.testimonialsData} />
      <FeaturedProgrammes
        featuredProgrammesData={data.wpPage.homepage.featuredProgrammes}
        featuredProgrammesBG={data.wpPage.homepage.featuredProgrammesBG}
      />
      <ImageText
        image={data.wpPage.homepage.promotionImage}
        text={data.wpPage.homepage.promotionText}
        title={data.wpPage.homepage.promotionTitle}
        button={data.wpPage.homepage.promotionButton}
      />
      <ProductionTools products={data.wpPage.homepage.products} />
      <Features posts={data.wpPage.homepage.posts} />
    </section>
  );
};
export default Home;

export const query = graphql`
  {
    wpPage(title: { eq: "Homepage" }) {
      seo {
        metaDesc
        title
      }
      homepage {
        heroBlock {
          background
          heading
          backgroundVideo
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }

        promotionText
        promotionTitle
        promotionButton {
          link
          text
        }
        promotionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }

        featuredProgrammesBG: programmesBackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }

        featuredProgrammes: pickProgrammes {
          programme {
            ... on WpProgramme {
              title
              slug
              programmeCategories {
                nodes {
                  slug
                }
              }
              interactive_programme {
                programmeIcon {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                  }
                }
                archiveButtonText
                archiveFieldsCopy
              }
            }
          }
        }

        testimonialsData: pickTestimonials {
          testimonialImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 900, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          testimonialImage2 {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          testimonialExternalLink
          testimonial {
            ... on WpTestimonial {
              id
              testimonialFields {
                archiveQuote
                courseCompleted {
                  ... on WpProgramme {
                    title
                  }
                }
              }
              title
              slug
            }
          }
        }

        products: pickProducts {
          product {
            ... on WpProduct {
              id
              title
              link
              slug
              productCategories {
                nodes {
                  name
                }
              }
              acf_product_info {
                samplePacks {
                  images {
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        posts: pickPosts {
          post {
            ... on WpPost {
              title
              link
              blog_post_fields {
                blogHero {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 3000, placeholder: BLURRED, formats: [AUTO, WEBP])
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
