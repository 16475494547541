
import styled from 'styled-components'

const Styling = styled.section`
width: 100%;
position: relative;
overflow: hidden;
background: var(--white);

    .intro{
    position: relative;
    width: 100vw;
    background:var(--white);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding:4rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:6rem 4rem;
        }

        @media only screen and (min-width: 1024px) {
        padding:10vw 10vw 8rem 10vw;
        }


        .title{
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        font-size:2.5rem;
        line-height:2rem;
        /* margin-bottom: 4rem; */
            
            @media only screen and (min-width: 350px) {
            font-size:3rem;
            line-height:2.6rem;
            letter-spacing: -0.2rem;
            }

            @media only screen and (min-width: 420px) {
            font-size:4rem;
            line-height:3rem;
            letter-spacing: -0.3rem;
            }
            
            @media only screen and (min-width: 610px) {
            font-size:5rem;
            line-height:4rem;
            letter-spacing: -0.4rem;
            }
            
            @media only screen and (min-width: 800px) {
            /* margin-bottom:clamp(2rem, 4vw, 8rem); */
            font-size: clamp(6rem,6vw,8rem);
            line-height: clamp(6rem/1.15,6vw/1.15,8rem/1.15);
            letter-spacing: -0.7rem;
            }

            .production_arrow{
            width: 0.7em;
            height: 0.7em;  
                
                @media only screen and (min-width: 1024px) {
                width: 0.8em;
                height: 0.8em;
                }
         
            }

        }
        .capsule_button{
            @media only screen and (min-width: 500px) {
                order:2;
            }
        }

    }
    
    .repositionedButton{
    padding:0 2rem 4rem 2rem;
    position: relative;

        @media only screen and (min-width: 500px) {
        display:none;
        }

    }

    .progressBarWrapper{
    display: none;

        @media only screen and (min-width: 1024px) {
        display: block;
        }
    }

    @media only screen and (max-width: 768px) {
        background:var(--black);
        color:var(--white);

       .production_tools{
            .intro{
                background: var(--black);

                .production_arrow path{
                    fill:var(--white);
                }
            }

            /* .progressBarWrapper {
            margin:4rem 0;

                .progressBar {
                background:rgba(255,255,255,.2);

                    .progressIndicator{
                    background: var(--white);
                    }
                }
            } */
       } 
    }
`

export { Styling }