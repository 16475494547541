
import styled from 'styled-components'

const Styling = styled.section`
position: relative;
width: 100%;
background:var(--white);

    @media only screen and (min-width: 768px) {
    display: flex;
    align-items: stretch;
    }

    .image{
    width:100%;

        @media only screen and (min-width: 768px) {
        width:50%;
        }

        @media only screen and (min-width: 1100px) {
        width:60%;
        }
    }
    .text-wrapper{
    position: relative;
    width:100%;
    padding:4rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:6rem 4rem;
        }
    
        @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        width:50%;
        }

        @media only screen and (min-width: 1100px) {
        width:40%;
        padding:clamp(2rem, 4vw, 8rem);
        }

        .title{
        position: relative;
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        font-size:2.5rem;
        line-height:2rem;
        margin-bottom: 4rem;
            
            @media only screen and (min-width: 350px) {
            font-size:3rem;
            line-height:2.6rem;
            letter-spacing: -0.2rem;
            }

            @media only screen and (min-width: 420px) {
            font-size:4rem;
            line-height:3rem;
            letter-spacing: -0.3rem;
            }
            
            @media only screen and (min-width: 610px) {
            font-size:5rem;
            line-height:4rem;
            letter-spacing: -0.4rem;
            }
            
            @media only screen and (min-width: 800px) {
            margin-bottom:clamp(2rem, 4vw, 8rem);
            font-size: clamp(6rem,6vw,8rem);
            line-height: clamp(6rem/1.15,6vw/1.15,8rem/1.15);
            letter-spacing: -0.7rem;
            }
        }

        .text{
        font-size:clamp(1.5rem, 1vw, 2rem);
        ${({theme}) => theme.tomato('regular')}  
        letter-spacing: -0.1rem;
        margin-bottom: 4rem;
        position: relative;
        line-height: clamp(1.5rem*1.5, 1vw*1.5, 2rem*1.5);
        }

    }
`

export { Styling }