import React, {useState, useRef, useEffect} from 'react'
import { Styling } from './styled.js'
import {BsArrowRight} from 'react-icons/bs'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby'
import Arrow from '../../../../images/arrow_right_black.inline.svg'

import { ProductSliderWrapperStyles } from '../../../reusable/ProductSlider/ProductSliderWrapperStyles'
import gsap from 'gsap'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination"

import SwiperCore, {
    Autoplay,Pagination,Navigation
} from 'swiper/core';
  
// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);
  
  

export default function Features({posts}) {
    const [currentPostIndex, setCurrentPostIndex] = useState(0)
    const [currentPost, setCurrentPost] = useState(posts[currentPostIndex])
    const [pauseOnHover, setPauseOnHover] = useState(false);
    const [switchForMobile, setSwitchForMobile] = useState(undefined);
    const [isSwiper, setIsSwiper] = useState();
    const list = useRef()
    const images = useRef()
    const prevRef = useRef()
    const nextRef = useRef()

    const updateProgressBar = (progress) => {
        gsap.to('.progressIndicator', {width:progress * 100+'%'})
    } 

    const updateActiveSlide = (e) => {
        if(typeof window !== 'undefined'){
            if(window.innerWidth > 1024 ){
                let target = e.target.closest('.swiper-slide');
                let needle = target.getAttribute('data-title');
                
                posts.filter((post, index) => {
                    if(post.post.title === needle){
                        setCurrentPostIndex(index)
                        setCurrentPost(post)
                    }
                    return post
                }) 
            }
        }
    }

    const checkViewport = (e) => {
        if(typeof window !== 'undefined'){
            if(window.innerWidth <= 1024 ){
                setSwitchForMobile(true)
            }else{
                setSwitchForMobile(false)
            }
        }
    }
    const initViewport = (e) => {
        if(typeof window !== 'undefined'){
            if(window.innerWidth <= 1024 ){
                setSwitchForMobile(true)
            }else{
                setSwitchForMobile(undefined)
            }
        }
    }

    useEffect(() => {   
        initViewport()
        if(typeof window !== 'undefined'){
            window.addEventListener('resize', checkViewport)
        }

        return () => {
            window.removeEventListener('resize', checkViewport)
        }
    },[])
    
    useEffect(() => {
        const length = posts.length
        let current = currentPostIndex
        let timer
        if(switchForMobile === undefined){
            timer = window.setInterval(function(){
                if(current === length -1){
                    current = 0
                    setCurrentPostIndex(0)
                }else{
                    if(pauseOnHover === false){
                        current++
                    }
                }
                setCurrentPost(posts[current])
            },3000)
        }

        if(switchForMobile === false){
            clearInterval(timer)
        }
        
        return () => clearInterval(timer);
    }, [currentPostIndex, setCurrentPostIndex, pauseOnHover, posts, switchForMobile])


    const targetRef = useRef()
    const targetRef2 = useRef()
    const newPositionRef = useRef()
    const originalPositionRef = useRef()
    const originalButtonPositionRef = useRef()

    const repositionElement = () => {
        if(typeof window !== undefined){
            const target = targetRef.current
            const target2 = targetRef2.current
            const newPosition = newPositionRef.current
            const originalPosition = originalPositionRef.current
            const originalPosition2 = originalPositionRef.current

            if(window.innerWidth < 1024 && window.innerWidth > 501){
                newPosition.parentNode.insertBefore(target, newPosition)
                newPosition.parentNode.insertBefore(target2, newPosition)
            }
            else if(window.innerWidth < 500 ){
                originalPosition.parentNode.insertBefore(target, originalPosition)
            }
            else{
                originalPosition.parentNode.insertBefore(target, originalPosition)
                originalPosition2.parentNode.insertBefore(target2, originalPosition2)
            }
        }
    }

    useEffect(()=> {
        repositionElement()

        if(typeof window !== undefined){
            window.addEventListener('resize', repositionElement)    
        }
       return () => {
            window.removeEventListener('resize', repositionElement)    
        }
    },[])


    return (
        <Styling>
            <div className="mobileIntro">
                <div ref={newPositionRef} className="hide"></div>
            </div>
            <div className="main-title" ref={targetRef2}><Arrow className="arrowFeatures" /> Features</div>
            <div className="image-wrapper" ref={images}>   
                    {
                        posts.map((post, index) => {
                            return(
                                <div className={post === currentPost ? 'image-wrap active' : 'image-wrap'} data-title={post.post.title}>
                                    <GatsbyImage image={getImage(post?.post?.blog_post_fields?.blogHero?.image?.localFile)}  className='image' alt={'test'} />
                                </div>
                            )
                        })
                    }     
            </div> 
            
            <div className="articles_wrapper">
 
                <ProductSliderWrapperStyles>
                    <Swiper
                    className="articles" 
                    ref={list}
                    enabled={false}
                    breakpoints={{
                        "300": {
                            'enabled': true,
                            "slidesPerView": 1.25,
                            "spaceBetween": 20,
                        },
                        "640": {
                            "slidesPerView": 2.5,
                            'enabled': true,
                            "spaceBetween":typeof window !== 'undefined' ? window.innerWidth / 100 * 2 : '',
                          },
                        "1023": {
                            "slidesPerView": 2.5,
                            'enabled': true,
                        },
                        "1024": {
                            'enabled': false
                        }
                    }}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                        setIsSwiper(swiper)
                    }}
                    autoplay={{
                        "delay": 2500,
                        "disableOnInteraction": true
                        }}
                    >
                    {
                        posts.map((post, index) => {
                            return(
                                <SwiperSlide data-title={post.post.title} className={post === currentPost ? 'active' : ''}>
                                    <Link to={post.post.link} onMouseEnter={(e) => {updateActiveSlide(e); setPauseOnHover(true)}} onMouseLeave={(e) => setPauseOnHover(false)} >
                                        <div className="inner">
                                        <GatsbyImage image={getImage(post?.post?.blog_post_fields?.blogHero?.image?.localFile)}  className='image' alt={'test'} />
                                        <div className="number">{index < 10 ? `0${index+1}.` : `${index+1}.`}</div>
                                        <div className="title">{post.post.title}</div>
                                        <BsArrowRight className="arrowRight" />
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            )
                        })
                    }
                    </Swiper>
                </ProductSliderWrapperStyles>
                <div>
                    <div ref={originalPositionRef}></div>
                    <Link ref={targetRef} to={'/features/'} className="capsule_button white">View all</Link>
                </div>
            </div>
        </Styling>
    )
}
