import styled from "styled-components";

const Styling = styled.section`
  width: 100vw;
  position: relative;

  .testimonials {
    position: relative;
    width: 100vw;
    height: auto;
    background: ${(props) =>
      props.darkLightMode === "light" || props.darkLightMode === undefined
        ? "var(--white)"
        : "var(--black)"};
    color: ${(props) =>
      props.darkLightMode === "light" || props.darkLightMode === undefined
        ? "var(--black)"
        : "var(--white)"};
    padding: 4rem;

    @media only screen and (min-width: 768px) {
      display: flex;
      padding: 8rem;
      align-items: center;
      height: 100vh;
      max-height: 800px;
    }

    @media only screen and (min-width: 1500px) {
      max-height: 1200px;
    }

    .image {
      width: clamp(320px, 35vw, 900px);
      /* width: 35vw; */
      height: 100%;
      position: absolute;
      right: 15%;

      .gatsby-image-wrapper {
        position: absolute;
        height: 100%;
      }
    }

    .mobileSlider {
      width: 100%;

      .swiper-2 {
        width: 100%;
        left: 0;
        margin-bottom: 4rem;

        .swiper-slide {
          min-height: 250px;

          @media only screen and (min-width: 350px) {
            min-height: 300px;
          }

          @media only screen and (min-width: 650px) {
            min-height: 400px;
          }
        }

        .second_image {
          width: 100px;
          padding-bottom: 100px;
          right: -2rem;
          bottom: -2rem;

          @media only screen and (min-width: 350px) {
            width: 150px;
            padding-bottom: 150px;
          }
        }

        .image {
          width: 100%;
          right: 0;
        }
      }
    }

    .swiper-3 .swiper-wrapper {
      position: absolute;
    }

    .second_image {
      height: 0;
      position: absolute;
      right: 7%;
      width: clamp(70px, 36.583333%, 200px);
      /* width: 36.583333%; */
      padding-bottom: clamp(70px, 37.583333%, 200px);
      /* padding-bottom: 37.583333%; */
      bottom: calc(0% + 4rem);

      @media only screen and (min-width: 1024px) {
        width: clamp(70px, 24.583333333%, 200px);
        z-index: 10;
        padding-bottom: clamp(70px, 24.583333333%, 200px);
        bottom: 8rem;
      }

      img {
        ${({ theme }) => theme.easing("0.5")}
      }

      a {
        cursor: pointer;

        &:before {
          width: 100%;
          height: 100%;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3));
          opacity: 0.7;
          z-index: 10;
          pointer-events: none;
          ${({ theme }) => theme.easing("0.5")}
        }
      }

      a,
      .gatsby-image-wrapper {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      a:hover {
        &:before {
          opacity: 1;
        }
        img {
          transform: scale(1.2);
        }
      }
    }

    .swiper-slide {
      opacity: 0 !important;
      pointer-events: none;
      transition: 0.3s opacity ease-in-out;

      &.swiper-slide-active {
        opacity: 1 !important;
        pointer-events: all;
      }
    }

    .swiper-2 {
      position: relative;
      width: 50%;
      height: 100%;
      overflow: visible;

      @media only screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }

      @media only screen and (max-width: 1150px) {
        left: 7%;
      }

      .swiper-wrapper {
        height: 70%;
      }
    }

    .left {
      left: 0;
      position: relative;
      width: 100%;

      @media only screen and (min-width: 768px) {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
      }

      @media only screen and (min-width: 1150px) {
        width: 43%;
        left: 7%;
      }

      .title {
        ${({ theme }) => theme.tomato("regular")}
        text-transform:uppercase;
        font-size: 2.5rem;
        line-height: 2rem;
        margin-bottom: 4rem;

        @media only screen and (min-width: 350px) {
          font-size: 3rem;
          line-height: 2.6rem;
          letter-spacing: -0.2rem;
        }

        @media only screen and (min-width: 420px) {
          font-size: 4rem;
          line-height: 3rem;
          letter-spacing: -0.3rem;
        }

        @media only screen and (min-width: 610px) {
          font-size: 5rem;
          line-height: 4rem;
          letter-spacing: -0.4rem;
        }

        @media only screen and (min-width: 800px) {
          margin-bottom: clamp(2rem, 4vw, 8rem);
          font-size: clamp(6rem, 6vw, 8rem);
          line-height: clamp(6rem/1.15, 6vw/1.15, 8rem/1.15);
          letter-spacing: -0.7rem;
        }

        &.sizing_override {
          font-size: clamp(3rem, 3vw, 9rem);
          line-height: 1em;
          letter-spacing: -0.07em;
        }

        .testimonials_arrow {
          width: 0.7em;
          height: 0.7em;

          @media only screen and (min-width: 1024px) {
            width: 0.8em;
            height: 0.8em;
          }

          path {
            fill: ${(props) =>
              props.darkLightMode === "light" || props.darkLightMode === undefined
                ? "var(--black)"
                : "var(--white)"};
          }
        }
      }

      .lower {
        width: 100%;
        display: block;

        .swiper-slide {
          display: flex;

          @media only screen and (max-width: 940px) {
            flex-wrap: wrap;
          }
        }

        .icon {
          display: none;

          @media only screen and (min-width: 940px) {
            display: block;
            width: clamp(4rem, 6vw, 13rem);
            height: clamp(4rem, 6vw, 13rem);
            margin-right: clamp(6rem/3, 6vw/3, 20rem/3);
          }

          img {
            object-fit: contain !important;
          }
        }

        .lower-quote-wrapper {
          width: 100%;

          @media only screen and (min-width: 940px) {
            width: calc(100% - clamp(8rem, 6vw, 13rem));
          }

          .quotes {
            margin-bottom: clamp(6rem/4, 6vw/4, 20rem/4);
          }

          .quote {
            width: 100%;
            font-size: clamp(2rem, 2vw, 8rem);
            line-height: clamp(2rem, 2vw, 8rem);
            text-transform: uppercase;
            letter-spacing: -0.2rem;
            margin-bottom: clamp(6rem/6, 6vw/6, 20rem/6);

            @media only screen and (min-width: 940px) {
              width: 80%;
            }
          }

          .stars {
            font-size: 2rem;
          }

          .stars,
          .person {
            margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
          }

          .buttons {
            display: inline-flex;

            .arrow {
              border: 1px solid
                ${(props) =>
                  props.darkLightMode === "light" || props.darkLightMode === undefined
                    ? "var(--black)"
                    : "var(--white)"};
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              margin-right: 1rem;
              ${({ theme }) => theme.easing("0.5")}

              &.next {
                margin-right: 4rem;
              }

              svg {
                width: 20px;
                height: 20px;
                ${({ theme }) => theme.easing("0.5")}
                /* top:2px; */
                            position: relative;
                fill: ${(props) =>
                  props.darkLightMode === "light" || props.darkLightMode === undefined
                    ? "var(--black)"
                    : "var(--white)"};

                @media only screen and (min-width: 1024px) {
                  width: 30px;
                  height: 30px;
                }
              }

              &:hover {
                background: ${(props) =>
                  props.darkLightMode === "light" || props.darkLightMode === undefined
                    ? "var(--black)"
                    : "var(--white)"};
                svg {
                  fill: ${(props) =>
                    props.darkLightMode === "light" || props.darkLightMode === undefined
                      ? "var(--white)"
                      : "var(--black)"};
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { Styling };
