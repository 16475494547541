import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import {Link} from 'gatsby'

export default function FeaturedProgrammes({featuredProgrammesData, featuredProgrammesBG}) {
    const theImage = getImage(featuredProgrammesBG?.localFile);
    return (
        <Styling>
            <div className="featured_programmes">
                <div className="content">
                    <h3 className="main-title">Featured <br /> Programmes</h3>
                    <ul className="programmes">
                    {
                        featuredProgrammesData.map((programme, index) => {
                            const title = programme.programme.title
                            const titleSplit = title.split(" ");
                            const finalTitle = titleSplit.map(title => <span>{title}</span>)
                            const programmeImage = getImage(programme?.programme?.interactive_programme?.programmeIcon?.localFile)
                            return(
                                <li key={`programme_homepage_${index}`} className="featured_programme">
                                    <Link to={`/programmes/${programme.programme.programmeCategories.nodes[0].slug}/${programme.programme.slug}/`} className="featured_programme_link">
                                    <div className="icon">
                                        <GatsbyImage image={programmeImage} className="image" alt={'test'} />
                                    </div>
                                    <h2 className="programme_title">{finalTitle}</h2>
                                    <div className="programme_text">{programme.programme.interactive_programme.archiveFieldsCopy}</div>
                                    <div className="capsule_button black">{programme.programme.interactive_programme.archiveButtonText}</div>
                                    </Link>
                                </li>
                            )
                        })
                    }
                    </ul>
                    <div className="footerText">
                        <div className="title">Not sure which one is right for you?</div>
                        <Link to="/programmes/interactive/" className='capsule_button transparent'>Compare programmes</Link>
                    </div>
                </div>
                <div className="bg">
                    <ParallaxProvider>
                        <Parallax y={[-20, 20]} tagOuter="figure">
                        <GatsbyImage image={theImage} className="image" alt={'test'} />
                        </Parallax>
                    </ParallaxProvider>
                </div>
            </div>
        </Styling>
    )
}
