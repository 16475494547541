
import styled from 'styled-components'

const Styling = styled.section`
position: relative;
display: flex;
align-items: center;
background: var(--white);
flex-wrap: wrap;
padding:0rem;


@media only screen and (min-width: 1024px) {
    padding: 8rem 4vw 16rem 10vw;  
}

    .swiper-container{
    padding-bottom: 0 !important;
        &:last-child{
        margin-bottom: 0 !important;
            
            @media only screen and (min-width: 1024px) {
                //margin-bottom: 8rem !important;
            }

        }
    }

    .capsule_button{
    margin:0 2rem 4rem 2rem;

        @media only screen and (min-width: 500px) {
            margin:3rem 0 0 3rem;
        }
    }

    .mobileIntro{
    display: inline-flex;
    align-items:center;
    justify-content: space-between;
    width: 100%;
    padding:0 2rem 0 0 ;

        @media only screen and (min-width: 350px) {
        padding:0 4rem 0 0;
        }

        .hide{
        display: none;
        }

        .main-title{
        order:1;
        }

        .capsule_button{
        order:2;
        }
    }

    .main-title{
    position: relative;
    ${({theme}) => theme.tomato('regular')}  
    text-transform:uppercase;
    display: inline-flex;
    margin-bottom: 4rem;
    padding:4rem 4rem 0 4rem;
    font-size:2.5rem;
    line-height:2rem;
        
        @media only screen and (min-width: 350px) {
        font-size:3rem;
        line-height:2.6rem;
        letter-spacing: -0.2rem;
        }

        @media only screen and (min-width: 420px) {
        font-size:4rem;
        line-height:3rem;
        letter-spacing: -0.3rem;
        }
        
        @media only screen and (min-width: 610px) {
        font-size:5rem;
        line-height:4rem;
        letter-spacing: -0.4rem;
        }
        
        @media only screen and (min-width: 800px) {
        font-size:clamp(4rem, 4vw, 6rem); 
        letter-spacing: -0.04em;
        }

        @media only screen and (min-width: 1024px) {
        transform: rotate(180deg);
        margin-bottom: 0;
        position:absolute;
        bottom:16rem;
        left:2.5rem;
        padding:0;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        }

        .arrowFeatures{
        position: relative;
        margin-right:1rem;
        width:1em;
        height:1em;

            @media only screen and (min-width: 1024px) {
            transform: rotate(90deg);
            margin-bottom:2rem;
            }

        }
    }

    .image-wrapper{
    width:40vw;
    height:40vw;
    position: relative;
    display: none;
        
        @media only screen and (min-width: 1024px) {
        display: block;
        }

        .image-wrap{
        opacity: 0;
        ${({theme}) => theme.easing('0.5')}  

            &.active{
            opacity: 1;
            }

        }

        .gatsby-image-wrapper{
        position: absolute;
        width: 100%;
        height:100%;
        }

    }



    .articles_wrapper{
    width:100%;
    padding:0;
    
        @media only screen and (min-width: 1024px) {
        padding:0 0 0 7vw;
        width:46vw;
        }




        .articles{
        width: 100%;

        @media only screen and (max-width: 1024px) {
            margin-bottom: 8rem;
        }

            .swiper-wrapper{
            width: 100%;

                @media only screen and (min-width: 1024px) {
                    flex-wrap: wrap;
                }
            }

            .swiper-slide{
            padding-bottom: 2rem;
            cursor: pointer;

                @media only screen and (min-width: 500px) {
                margin-bottom: 2rem;                
                }

                @media only screen and (min-width: 1024px) {
                width: 100% !important;
                border-bottom:1px solid rgba(0,0,0,.1);
                }

                a{
                    @media only screen and (min-width: 1024px) {
                    width: 100% !important;
                    }
                }
                    
                &:last-child{
                margin-bottom:0;
                padding-bottom: 0;
                border-bottom: 0;
                }

                
            
                a{
                text-decoration: none;
                color:inherit;

                    @media only screen and (max-width: 1024px) {
                    height:100%;
                    width:100%;
                    }

                }

               .inner{                
                position: relative;
                ${({theme}) => theme.easing('0.5')} 

                    @media only screen and (min-width: 1024px) {
                        opacity: 0.3;
                    }

                    .number{
                    font-size:1.3rem;
                    margin-bottom: 2rem;

                        @media only screen and (max-width: 1024px) {
                        display: none;
                        }

                    }

                    .gatsby-image-wrapper{
                    display: block;
                    width:100%;
                    height:0;
                    padding-bottom: 100%;
                    margin-bottom: 2rem;

                        @media only screen and (min-width: 1024px) {
                        display: none;
                        }

                    }

                    .title{
                    font-size:clamp(2.2rem, 1vw, 6rem);
                    ${({theme}) => theme.tomato('regular')}  
                    text-transform:uppercase;
                    letter-spacing: -0.04em;
                    margin-bottom: 1rem;
                    width: 100%;
                    
                        @media only screen and (min-width: 1024px) {
                        width: calc(100% - 10rem);
                        font-size:clamp(1.8rem, 1vw, 5rem); 
                        }

                    }

                    .arrowRight{
                    position: absolute;
                    right:0;
                    top:50%;
                    font-size:3rem;
                    opacity: 0;
                    ${({theme}) => theme.easing('0.5')}  
                    transform:translateX(3rem);
                    }

               }

              

                &.active{
                    @media only screen and (min-width: 1024px) {    
                        .inner{
                        opacity: 1;

                            .arrowRight{
                            opacity: 1;
                            transform:translateX(0);
                            }

                        }
                    }
                }

            }
        }

    }

`

export { Styling }