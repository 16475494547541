import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';


export default function Badge({course}) {
    const data = useStaticQuery(graphql`
    {
        allWpProgramme(
            filter: {interactive_programme: {programmeIconWithText: {localFile: {absolutePath: {ne: null}}}}}
        ) {
        nodes {
            title
            interactive_programme {
                programmeIconWithText {
                    localFile{
                        childImageSharp {
                          gatsbyImageData(
                            width: 800
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                }
            }
        }
      }
    }
  `)
    const allIcons = data.allWpProgramme.nodes
    return (
        <>
            {
                allIcons.map(icon => {
                    let image
                    if(icon.title === course){
                        const theImage = getImage(icon?.interactive_programme?.programmeIconWithText?.localFile);
                        image = <GatsbyImage image={theImage} className="icon" alt={'test'} />
                    }
                    return image
                })
            }
        </>
    )
}
