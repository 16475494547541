
import styled from 'styled-components'

const Styling = styled.section`
position: relative;
overflow: hidden;
width: 100vw;

    .featured_programmes{
    position: relative;
    width: 100vw;
    padding:clamp(8rem, 8vw, 12rem) 0;
    display: flex;
    align-items: center;


        .content{
        position: relative;
        z-index: 10;
        color:var(--white);
        margin: 0 auto;
        text-align:center;
        width: 100%;
        padding:0 2rem;

        @media only screen and (min-width: 350px) {
        padding:0 4rem;
        }

        @media only screen and (min-width: 1024px) {
            width: 90%;
            padding:0;
        }
        @media only screen and (min-width: 1200px) {
            width: 80%;
        }

            .main-title{
            position: relative;
            ${({theme}) => theme.tomato('regular')}  
            text-transform:uppercase;
            font-size:2.5rem;
            line-height:2rem;
            margin-bottom: 4rem;
                
                @media only screen and (min-width: 350px) {
                font-size:3rem;
                line-height:2.6rem;
                letter-spacing: -0.2rem;
                }

                @media only screen and (min-width: 420px) {
                font-size:4rem;
                line-height:3rem;
                letter-spacing: -0.3rem;
                }
                
                @media only screen and (min-width: 610px) {
                font-size:5rem;
                line-height:4rem;
                letter-spacing: -0.4rem;
                }
                
                @media only screen and (min-width: 800px) {
                margin-bottom:clamp(2rem, 4vw, 8rem);
                font-size: clamp(6rem,6vw,8rem);
                line-height: clamp(6rem/1.15,6vw/1.15,8rem/1.15);
                letter-spacing: -0.7rem;
                }
            }

            .programmes{
            width: 100%;
            position: relative;
            margin-bottom: 6rem;
            display: block;
            
            @media only screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2rem;
            }

            @media only screen and (min-width: 1024px) {
            grid-gap: clamp(2rem, 4vw, 8rem);
            margin-bottom: clamp(6rem, 4vw, 8rem);
            }

                .featured_programme{
                background:var(--white);
                border-radius: 0.75rem;
                color:var(--black);
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 2rem;

                    @media only screen and (min-width: 350px) {
                    margin-bottom: 4rem;
                    }

                    @media only screen and (min-width: 768px) {
                    margin-bottom: 0;
                    }
                
                    .featured_programme_link{
                    text-decoration:none;
                    color:var(--black);
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding:4rem 2rem;

                    @media only screen and (min-width: 1024px) {
                    padding:4rem 4rem 6rem 4rem;
                    }

                    

                        .icon{
                        max-width: 100px;
                        margin:0 auto 2rem auto;
                        }

                        .programme_title{
                        font-size:clamp(2.5rem, 1.5vw, 4rem);
                        ${({theme}) => theme.tomato('regular')}  
                        text-transform:uppercase;
                        letter-spacing: -0.1rem;
                        margin-bottom: 2rem;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                            
                            span{
                                width: 100%;
                            }

                        }
                    
                        .programme_text{
                        font-size:clamp(1.5rem, 1vw, 2rem);
                        ${({theme}) => theme.tomato('regular')}  
                        letter-spacing: -0.1rem;
                        margin-bottom: 4rem;
                        position: relative;
                        line-height: clamp(1.5rem*1.5, 1vw*1.5, 2rem*1.5);
                        }

                        .capsule_button{
                        align-self: flex-end;
                        }

                        &:hover{
                            .capsule_button{
                                background:var(--white);
                                color:var(--black);
                            }
                        }
                    }
                }
            }
        }

        .bg{
        position: absolute;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        z-index: 0;
        background: var(--black);

            .gatsby-image-wrapper{
            opacity: 0.3;
            }
        }

        .footerText{
            .title{
            font-size:clamp(2.5rem, 1.5vw, 4rem);
            ${({theme}) => theme.tomato('regular')}  
            text-transform:uppercase;
            letter-spacing: -0.1rem;
            width: 100%; 
            position: relative;
            margin-bottom: 2rem;

                @media only screen and (min-width: 1024px) {
                margin-bottom: 6rem;
                }

            }
            

            
            .capsule_button{
                &:hover{
                    background:var(--white);
                    color:var(--black);
                }
            }
        }
    }
`

export { Styling }