import React, {useRef, useEffect} from 'react'
import { Styling } from './styled.js'
import Arrow from '../../../../images/arrow_right_black.inline.svg'
import {Link} from 'gatsby'
import ProductSlider from '../../../reusable/ProductSlider'


export default function ProductionTools({products}) {

    const targetRef = useRef()
    const newPositionRef = useRef()
    const originalPositionRef = useRef()

    const repositionElement = () => {
        if(typeof window !== undefined){
            const target = targetRef.current
            const newPosition = newPositionRef.current
            const originalPosition = originalPositionRef.current

            if(window.innerWidth < 500 ){
                newPosition.parentNode.insertBefore(target, newPosition)
            }else{
                originalPosition.parentNode.insertBefore(target, originalPosition)
            }
        }
    }

    useEffect(()=> {
        repositionElement()

        if(typeof window !== undefined){
            window.addEventListener('resize', repositionElement)    
        }
       return () => {
            window.removeEventListener('resize', repositionElement)    
        }
    },[])

    return (
        <Styling>
            <div className="production_tools">
                <div className="intro">
                    <h3 ref={originalPositionRef} className="title">
                        <div>Production</div> 
                        <div><Arrow className="production_arrow" /> Tools</div> 
                    </h3>
                    <Link to={'/shop/'} ref={targetRef} className="capsule_button white">View all</Link>
                </div>
                <ProductSlider 
                products={products} 
                hasTitle={false} 
                hasArrows={false} 
                hasViewAll={false} 
                showProgress={true} 
                />
                <div className="repositionedButton">
                    <div ref={newPositionRef}></div>
                </div>
            </div>
        </Styling>
    )
}
