import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby'

export default function ImageText({
    image, text, title, button, className
}) {
    const theImage = getImage(image?.localFile);
    return (
        <Styling className={className}>
            <GatsbyImage image={theImage} className="image" alt={'test'} />              
            <div className="text-wrapper">
                <div className="title">{title}</div>
                <div className="text">{text}</div>
                <Link to={button.link} className="capsule_button white">{button.text}</Link>
            </div>
        </Styling>
    )
}
